/* Tweak to change the look and feel */
:root {
    --button-background: dodgerblue;
    --button-color: white;

    --dropdown-highlight: dodgerblue;
    --dropdown-width: 160px;
    --dropdown-max-width: 150px;
    --dropdown-background: white;
    --dropdown-color: black;
}

a.button {
    /* Frame */
    display: inline-block;
    box-sizing: border-box;

    /* Style */
    border: none;
    background: var(--button-background);
    color: var(--button-color);
    cursor: pointer;
}

/* Dropdown styles */
.dropdown {
    position: relative;
    padding: 0;
    margin-right: 1em;

    border: none;
}

.dropdown summary {
    list-style: none;
    list-style-type: none;
}

.dropdown > summary::-webkit-details-marker {
    display: none;
}

.dropdown summary:focus {
    outline: none;
}

.dropdown summary:focus a.button {
    border: 2px solid white;
}

.dropdown summary:focus {
    outline: none;
}

.dropdown ul {
    position: absolute;
    margin: 10px 0 0 0;
    padding: 10px 0;
    width: var(--dropdown-width);
    left: 50%;
    margin-left: calc((var(--dropdown-width) / 2) * -2.2);
    box-sizing: border-box;
    top: -20px;
    z-index: 2;

    background: var(--dropdown-background);
    border-radius: 6px;
    cursor: pointer;
    list-style: none;
    @apply border-2 border-gray-100 shadow-2xl;
}

.dropdown ul li {
    padding: 0;
    margin: 0;
}

.dropdown ul li a {
    display: inline-block;
    padding: 10px 0.8rem;
    width: 100%;
    box-sizing: border-box;

    color: var(--dropdown-color);
    text-decoration: none;
    cursor: pointer;
    @apply mr-1 ml-1;
}

.dropdown ul li a:hover {
    // background-color: var(--dropdown-highlight);
    color: white;
    z-index: 9000px;
    max-width: var(--dropdown-max-width);
    @apply bg-gradient-to-r from-fuchsia-600 to-pink-600 rounded-md mr-1 ml-1 hover:text-white;
}

/* Dropdown triangle */
.dropdown ul::before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    left: var(--dropdown-width);
    margin-left: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--dropdown-background) transparent;
    transform: rotate(90deg);
    @apply shadow-2xl;
}

/* Close the dropdown with outside clicks */
.dropdown > summary::before {
    display: none;
}

.dropdown[open] > summary::before {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}
