@import "~@ng-select/ng-select/themes/default.theme.css";
// Here you can add other styles
// Cosmo 4.3.1
// Bootswatch

// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;700&family=Quicksand:wght@300;500;700&display=swap" !default;
@import url($web-font-path);

// Navbar ======================================================================

// Buttons =====================================================================

// Typography ==================================================================
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  -webkit-font-smoothing: antialiased;
}

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

// Indicators ==================================================================

// Progress bars ===============================================================

.progress {
  @include box-shadow(none);
  .progress-bar {
    font-size: 8px;
    line-height: 8px;
  }
}

// Containers ==================================================================
.app-body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  @apply bg-gray-200;
}
.sidebar {
  @apply bg-gradient-to-r from-blue-500 to-blue-600 text-sm;
}
.sidebar .nav-dropdown.open .nav-link {
  @apply text-sky-50 m-2 pl-4 rounded-md;
}
.sidebar .nav-dropdown.open {
  @apply bg-gradient-to-r from-blue-500 to-blue-600;
}
.nav-item .nav-dropdown {
  @apply m-4 rounded-lg;
}
.sidebar .nav-link {
  @apply text-sky-50;
}
.sidebar .nav-link .nav-icon {
  @apply text-sky-50;
}
.sidebar .nav-link:hover {
  @apply bg-gradient-to-r from-fuchsia-600 to-pink-600;
}
.sidebar .nav-link:hover .nav-icon {
  @apply text-sky-50;
}
app-sidebar-nav-link-content {
  @apply hover:text-sky-50;
}
.sidebar .nav-link.active {
  @apply m-2 rounded-md text-sky-50 drop-shadow-lg bg-gradient-to-r from-fuchsia-600 to-pink-600;
}
.sidebar .nav-link.active .nav-icon {
  @apply text-sky-50;
}
.sidebar .nav-link.active > app-sidebar-nav-link-content {
  @apply text-sky-50;
}
.sidebar .nav-dropdown-toggle {
  @apply m-2 rounded-md bg-gradient-to-r from-blue-500 to-blue-600;
}
app-sidebar-nav-dropdown {
  @apply bg-gradient-to-r from-blue-500 to-blue-600;
}
.navbar-brand {
  @apply ml-8;
}
.breadcrumb-item {
  @apply text-sm;
}

.app-login {
  background-image: url("./../assets/img/login/login_back_5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

app-sidebar-nav-items > app-sidebar-nav-link > a {
  // margin-left: 20px;
}

#demopurpose {
  background-color: #edf0f5;
}

.profile-card {
  position: relative;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  // font-family: "Poppins", sans-serif;
}

.profile-card .btn-edit {
  position: absolute;
  top: 1rem;
  right: 1.2rem;
  font-size: 1.4rem;
}

.profile-card .profile-picture {
  width: 7.4rem;
}

.profile-card .quote-text {
  font-size: 0.85rem;
  line-height: 1.55rem;
}

.profile-card .social-section {
  margin-top: 1rem;
}

.profile-card .social-section a {
  font-size: 1rem;
  line-height: 2.15rem;
  width: 2.2rem;
  height: 2.2rem;
  border: 1px solid #d6d6d6;
  color: blue;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.profile-card .social-section a:hover {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.form-group.required .control-label::after {
  content: " *";
  color: red;
  font-weight: bold;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #ff0039;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  ~ .invalid-feedback {
    display: block;
  }
}

ng-select.ng-invalid.ng-touched {
  border-color: #ff0039;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  ~ .invalid-feedback {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .boton-inline {
    margin-top: 30px;
  }
}

.afu-select-btn {
  margin-top: 8px !important;
}

.border-width-principal {
  border-width: 5px;
}

.input-group .custom {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.debug-red {
  border: 1px solid red;
}
.debug-green {
  border: 1px solid green;
}
.debug-blue {
  border: 1px solid blue;
}
