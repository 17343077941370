@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.btn--sm {
    @apply text-xs w-auto;
}
.btn--primary {
    @apply border-2 border-slate-600 bg-slate-600 text-white rounded-md mt-3 hover:cursor-pointer hover:bg-slate-800 hover:border-slate-800 pt-2 pb-2 pr-2 pl-2 w-full;
}
.btn--secondary {
    @apply border-2 border-indigo-400 text-indigo-500 rounded-md mt-3 hover:cursor-pointer hover:border-indigo-600 hover:text-indigo-700  pt-2 pb-2 pr-2 pl-2 w-full;
}
.btn--success {
    @apply bg-green-600 text-white rounded-lg hover:cursor-pointer hover:ring-4 hover:ring-green-400 pt-2 pb-2 pr-4 pl-4;
}
.btn--danger {
    @apply bg-red-600 text-white rounded-lg hover:cursor-pointer hover:ring-4 hover:ring-red-400 pt-2 pb-2 pr-4 pl-4;
}
.btn--main {
    @apply bg-sky-600 text-white rounded-lg hover:cursor-pointer hover:ring-4 hover:ring-sky-400 pt-2 pb-2 pr-4 pl-4;
}
.btn--outline {
    // ring-2 ring-indigo-400 text-indigo-500 rounded-md hover:cursor-pointer hover:border-indigo-600 hover:text-indigo-700  pt-2 pb-2 pr-2 pl-2
    @apply ring-2 ring-indigo-400 text-indigo-500 rounded-lg hover:text-indigo-600 hover:cursor-pointer hover:ring-4 hover:ring-indigo-500 pt-1.5 pb-1.5 pr-4 pl-4;
}
.btn--dropdown {
    @apply bg-sky-600 text-white rounded-t-lg rounded-r-lg rounded-b-lg rounded-l-none hover:cursor-pointer pt-2 pb-2 pr-4 pl-4;
}
.btn--pill {
    @apply bg-sky-600 text-white rounded-md hover:cursor-pointer hover:ring-2 hover:ring-sky-400 p-1;
}
.btn--pill-l {
    @apply bg-sky-600 text-white rounded-l-md hover:cursor-pointer hover:ring-2 hover:ring-sky-400 p-1;
}
.btn--pill-r {
    @apply bg-sky-600 text-white rounded-r-md hover:cursor-pointer hover:ring-2 hover:ring-sky-400 p-1;
}
.form--control {
    @apply rounded-lg border-gray-300 text-sm;
}
.input-login {
    @apply bg-blue-100 border-2 border-blue-500 rounded-xl mt-3 placeholder:text-sm placeholder:text-slate-600 pl-3 pt-2 pb-2 hover:ring-2;
}
.input {
    @apply py-2 pl-12 pr-2 border-2 border-gray-600 rounded-md outline-none focus:border-white focus:ring-2 focus:ring-orange-500 focus:outline-none appearance-none;
}
.link-primary {
    @apply text-orange-600 text-sm hover:underline hover:text-orange-600;
}
.icon-input {
    @apply absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-gray-500 h-6 w-6;
}

.span-label {
    @apply mb-1 text-sm pl-2;
}

.container-register {
    @apply m-4 h-2/6 w-5/12 mx-auto;
}
.form-register {
    @apply h-full;
}
.grid-register {
    @apply grid grid-cols-1 gap-2 lg:grid-rows-4 lg:grid-cols-2 lg:gap-4 p-8;
}
.app-recovery {
    background-image: url("./../assets/img/login/login_back_5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    @apply flex h-screen justify-center items-center;
}
.card-register {
    @apply bg-white mx-auto rounded-3xl shadow-2xl shadow-gray-800;
}

.row-submit-register-two-columns {
    text-align: center;
    @apply hidden lg:block w-full col-span-2 justify-center h-3/6;
}

.row-submit-register-one-column {
    @apply lg:hidden w-full flex gap-2 justify-center;
}

.row-submit-register button {
    @apply self-center;
}

.title-register {
    @apply pt-10 pl-10 text-2xl text-orange-500 font-bold;
}

.subtitle-register {
    @apply pt-2 pl-10 text-sky-500 font-semibold;
}

.no-valid {
    @apply text-orange-500 text-sm;
}
.recovery-password {
    @apply pl-8 pr-8 pb-8 flex flex-col lg:flex-row gap-4;
}

.tw--table {
    @apply table-auto w-full text-left text-sm;
}
.badge--sm {
    @apply inline-block rounded-full text-sm pt-1 pb-1 pl-2 pr-2 text-white;
}
.badge--xs {
    @apply inline-block rounded-full text-xs pt-1 pb-1 pl-2 pr-2 text-white;
}
.badge--red {
    @apply bg-red-600 ring ring-red-200;
}
.badge--sky {
    @apply bg-sky-600 ring ring-cyan-200;
}
.badge--fuchsia {
    @apply bg-fuchsia-600 ring ring-fuchsia-200;
}
.modal--header {
    @apply text-sm rounded-xl;
}
.modal--body {
    @apply text-sm;
}
.table--footer {
    @apply m-4 p-4 divide-y-2;
}
.table--footer > th {
    @apply pt-4 pb-4;
}
.tw--table-row {
    @apply hover:bg-purple-50;
}
.tw--table-row-header {
    @apply px-4 py-3 font-medium text-gray-900 text-sm bg-gray-100;
}
.tw--table-row-header-l {
    @apply rounded-tl rounded-bl;
}
.tw--table-row-header-r {
    @apply rounded-tr rounded-br;
}
.tw--table-row {
    @apply border-t-2 border-gray-200 px-4 py-3;
}
.tw--table-row-l {
    @apply rounded-tl rounded-bl;
}
.tw--table-row-r {
    @apply rounded-tr rounded-br;
}
.tw--card {
    @apply shadow-2xl rounded-2xl;
}
.tw--switch {
    @apply text-sm w-4 h-4;
}
.card--header {
    background-color: transparent;
    @apply rounded-t-2xl;
}
.card--body {
    background-color: transparent;
    @apply rounded-b-2xl;
}
.paginator {
    @apply mr-2;
}
.paginator--label {
    @apply text-blue-600 font-bold;
}
.text--red {
    @apply text-red-500;
}
